import useSWR from 'swr';
import { useAppContext, UserType } from '../context/appContext';
import { HttpEndpoints } from '../data/httpEndpoints';
import { User } from '../typings/backend-types';
import authenticatedFetcher from '../data/authenticatedFetcher';
import { useErrorPopupContext } from '../context/errorPopupContext';
import { useTranslation } from 'react-i18next';

export function useUserTeam(userId?: string) {
  const { user: loggedInUser, userType } = useAppContext(UserType.BOTH);
  const { setErrorMessage } = useErrorPopupContext();
  const { t } = useTranslation();

  const { data: team } = useSWR<User[]>(
    () =>
      userType === UserType.EMPLOYEE &&
      HttpEndpoints.UserEndpoints.getForSuperior(userId || loggedInUser.id),
    authenticatedFetcher,
    {
      fallbackData: [],
      revalidateOnFocus: false,
      onError: () => setErrorMessage(t('common.teamFetchFailed')),
    },
  );

  return team || [];
}
