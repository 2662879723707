import React, { Fragment, FunctionComponent, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Button, ButtonThemeColor } from '@components/Common/Buttons/Button';

interface Props {
  title?: string | null;
  message?: string | React.ReactElement | null;
  open: boolean;
  onClose: () => void;
  onOk?: () => void;
  closeText?: string | null;
  okText?: string | null;
  hideIcon?: boolean;
  icon?: JSX.Element;
  accentColor?: ButtonThemeColor;
  isOkActionLoading?: boolean;
}

// modal based on the tailwind component https://tailwindui.com/components/application-ui/overlays/modals#component-f39bfe26a95719b1611d27f969be9a24
export const Popup: FunctionComponent<Props> = ({
  open,
  title,
  message,
  onClose,
  onOk,
  closeText,
  okText,
  hideIcon,
  icon,
  accentColor,
  isOkActionLoading,
}) => {
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation('translation', { keyPrefix: 'popup' });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[100] inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div>
                {!hideIcon &&
                  (icon || (
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                  ))}
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">{message}</div>
                  </div>
                </div>
              </div>
              <div
                className={
                  'mt-5 sm:mt-6 sm:grid sm:gap-3 sm:grid-flow-row-dense ' +
                  (onOk ? 'sm:grid-cols-2' : 'sm:grid-cols-1')
                }
              >
                {onOk && (
                  <div className="sm:col-start-2 w-full">
                    <Button
                      color={accentColor}
                      onClick={onOk}
                      loading={isOkActionLoading}
                      wide
                    >
                      <span className="font-medium text-sm w-max">
                        {okText}
                      </span>
                    </Button>
                  </div>
                )}

                <div className="sm:col-start-1 w-full mt-3 sm:mt-0">
                  <Button
                    onClick={onClose}
                    ref={cancelButtonRef}
                    wide
                    color="light"
                  >
                    <span className="font-medium text-sm">
                      {closeText ?? t('cancel')}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Popup;

Popup.defaultProps = {
  title: 'Oops',
  message: 'Something went wrong',
  okText: 'Ok',
  accentColor: 'primary',
};
