import React, { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { DropdownMenu } from '../Common/Dropdown/DropdownMenu';
import { Routes } from '../../utilities/routes';
import { UserType, useAppContext } from '../../context/appContext';

export default function AccountDropDown(): JSX.Element {
  const { user: auth0User, logout } = useAuth0();
  const { userType } = useAppContext(UserType.BOTH);

  const { t } = useTranslation('translation', {
    keyPrefix: 'profileSettings.dropdown',
  });

  const router = useRouter();

  const dropdownOptions = useMemo(() => {
    function logoutUser() {
      logout({ returnTo: window.location.origin });
    }
    if (userType === UserType.STUDENT) {
      return [
        {
          label: t('logout'),
          onClick: logoutUser,
        },
      ];
    }
    return [
      {
        label: t('accountSettings'),
        onClick: () => router.push(Routes.Profile.Overview),
      },
      {
        label: t('logout'),
        onClick: logoutUser,
      },
    ];
  }, [userType, router, t, logout]);

  return (
    <DropdownMenu options={dropdownOptions}>
      <span className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500 flex items-center rounded-full lg:hover:bg-accent-100 lg:p-2">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          className="h-10 w-10 rounded-full"
          src={auth0User?.picture}
          alt=""
        />
        <span className="hidden ml-3 text-gray-500 text-sm font-medium lg:block">
          <span className="sr-only">{t('openUserMenu')} </span>
          {auth0User?.nickname}
        </span>
        <ChevronDownIcon
          className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
          aria-hidden="true"
        />
      </span>
    </DropdownMenu>
  );
}
