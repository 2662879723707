import { FunctionComponent } from 'react';
import { LoadingIndicator } from './LoadingIndicator';

export const LoadingScreen: FunctionComponent = () => {
  return (
    <div className="h-screen w-screen grid justify-items-center items-center">
      <LoadingIndicator />
    </div>
  );
};
