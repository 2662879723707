import { useCallback } from 'react';
import { UserType, useAppContext } from '../context/appContext';
import { Permission, Scope } from '../typings/roleConfig';

export default function useUserReadAccess(): (
  permission: Permission,
) => boolean {
  const { user, userType } = useAppContext(UserType.BOTH);

  const hasReadAccess = useCallback(
    (permission: Permission): boolean => {
      if (userType === UserType.STUDENT) return false;
      if (!user.role) return false;
      if (user.role.isAdmin) return true;
      const userPermission = user.role.permissions[permission];
      if (userPermission === undefined) return false;
      return userPermission >= Scope.READ;
    },
    [user, userType],
  );

  return hasReadAccess;
}
