import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DropdownMenu } from '@components/Common/Dropdown/DropdownMenu';
import { FEATURE } from 'FEATURES';
import useFeatureToggles from 'hooks/useFeatureToggles';

export default function LanguageSelector(): JSX.Element | null {
  const { isFeatureEnabled } = useFeatureToggles();
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'languageSelector',
  });

  if (!isFeatureEnabled(FEATURE.I18N)) {
    return null;
  }

  return (
    <DropdownMenu
      options={[
        {
          label: t('de'),
          onClick: () => {
            void i18n.changeLanguage('de');
            dayjs.locale('de');
          },
        },
        {
          label: t('en'),
          onClick: () => {
            void i18n.changeLanguage('en');
            dayjs.locale('en');
          },
        },
      ]}
      align="left"
      verticalAlign="top"
    >
      <div className="flex items-center text-gray-500 cursor-pointer hover:bg-accent-100 p-3 rounded-full font-medium">
        <GlobeAltIcon className="w-6 h-6 mr-4" />
        {t(i18n.language)}
      </div>
    </DropdownMenu>
  );
}
