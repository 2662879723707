import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { FunctionComponent, useState } from 'react';
import { classNames } from 'utilities/classNames';
import { assert } from 'utilities/errors';

interface Props {
  className?: string;
  heading?: string | null;
  isCollapsible?: boolean;
  id?: string;
}

export const Panel: FunctionComponent<Props> = ({
  className,
  children,
  heading,
  isCollapsible,
  id,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!isCollapsible);

  assert(!isCollapsible || heading, 'Collapsible panels must have a heading');

  return (
    <div
      className={classNames(
        'bg-white sm:rounded-xl -mx-4 sm:mx-0 shadow sm:shadow-none',
        className,
      )}
      id={id}
    >
      {heading && (
        <div
          className={classNames(
            'py-4 px-6 sm:rounded-t-xl select-none',
            isCollapsed && 'sm:rounded-b-xl',
            isCollapsible && 'cursor-pointer',
            isCollapsible
              ? 'bg-accent-600 text-white'
              : 'bg-accent-100 text-accent-600',
          )}
          onClick={() => isCollapsible && setIsCollapsed((c) => !c)}
        >
          {isCollapsible &&
            (isCollapsed ? (
              <ChevronDownIcon className="w-5 h-5 inline-block mr-2 stroke-2" />
            ) : (
              <ChevronUpIcon className="w-5 h-5 inline-block mr-2 stroke-2" />
            ))}
          {heading}
        </div>
      )}
      {!isCollapsed && <div className="py-5 px-5 sm:px-7">{children}</div>}
    </div>
  );
};
