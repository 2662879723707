// Users can also have specific other access rights specified by the access rights table,
// these are not included in the role config and are available to all users.
// This affects time-tracking and the calendar.
// IMPORTANT: since the permissions are stored as a json column, the enum values should not be changed
export enum Permission {
  // calendar
  OWN_CALENDAR = 'OWN_CALENDAR',
  ALL_CALENDARS = 'ALL_CALENDARS',
  ALL_VEHICLE_CALENDARS = 'VEHICLE_CALENDAR',
  OWN_VEHICLE_CALENDAR = 'OWN_VEHICLE_CALENDAR',
  EDIT_PAST_EVENTS = 'EDIT_PAST_EVENTS',
  CALENDAR_SELECT_ALL_VEHICLES = 'CALENDAR_SELECT_ALL_VEHICLES',
  OWN_VACATION = 'OWN_VACATION',
  CALENDAR_AUDIT_LOG = 'CALENDAR_AUDIT_LOG',
  // time-tracking
  OWN_TIME_TRACKING = 'OWN_TIME_TRACKING',
  ALL_TIME_TRACKING = 'ALL_TIME_TRACKING',
  // vehicles
  VEHICLES = 'VEHICLES',
  VEHICLE_DETAIL = 'VEHICLE_DETAIL',
  // Student-specific data & pages
  OWN_STUDENTS_INFO = 'OWN_STUDENTS_INFO', // "students" = personal information, lesson list, archiving & dropping out
  ALL_STUDENTS_INFO = 'ALL_STUDENTS_INFO',
  STUDENT_ASSIGN_INSTRUCTOR = 'STUDENT_ASSIGN_INSTRUCTOR',
  STUDENT_EXPORT = 'STUDENT_EXPORT',
  STUDENT_ATTACHMENTS = 'STUDENT_ATTACHMENTS',
  STUDENT_PAYMENTS = 'STUDENT_PAYMENTS',
  STUDENT_INVOICES = 'STUDENT_INVOICES',
  STUDENT_BALANCE = 'STUDENT_BALANCE',
  STUDENT_PAYMENT_REQUESTS = 'STUDENT_PAYMENT_REQUESTS',
  STUDENT_PROGRESS_CARDS = 'STUDENT_PROGRESS_CARDS',
  STUDENT_EXAMS = 'STUDENT_EXAMS',
  // finance
  ALL_PAYMENTS = 'ALL_PAYMENTS',
  ALL_INVOICES = 'ALL_INVOICES',
  INVOICE_MAILING = 'INVOICE_MAILING',
  BILLING_TYPES = 'BILLING_TYPES',
  INVOICE_SETTINGS = 'INVOICE_SETTINGS',
  PAYMENT_SETTINGS = 'PAYMENT_SETTINGS',
  PRODUCTS = 'PRODUCTS',
  INITIATE_PAYMENT = 'INITIATE_PAYMENT',
  // hr
  USERS = 'USERS',
  USER_ROLES = 'USER_ROLES',
  // organization settings & resources
  ORGANIZATION_SETTINGS = 'ORGANIZATION_SETTINGS',
  HOLIDAYS = 'HOLIDAYS',
  MEETING_POINTS = 'MEETING_POINTS',
  OFFICE_LOCATIONS = 'OFFICE_LOCATIONS',
  STUDENT_GROUPS = 'STUDENT_GROUPS',
  DROPOUT_REASONS = 'DROPOUT_REASONS',
  BRANDING = 'BRANDING',
  LESSON_REQUEST_CONFIG = 'LESSON_REQUEST_CONFIG',
  SMS_SETTINGS = 'SMS_SETTINGS',
  LOCK_PERIODS = 'LOCK_PERIODS',
  EXAMINERS = 'EXAMINERS',
  SYLLABI = 'SYLLABI',
  // success reports
  OWN_SUCCESS_REPORT = 'OWN_SUCCESS_REPORT',
  ALL_SUCCESS_REPORTS = 'ALL_SUCCESS_REPORTS',
  // to-dos
  OWN_TODOS = 'OWN_TODOS',
  SENT_TODOS = 'SENT_TODOS',
  SEND_TODOS = 'SEND_TODOS',
  // lesson requests
  OWN_LESSON_REQUESTS = 'OWN_LESSON_REQUESTS',
  ALL_LESSON_REQUESTS = 'ALL_LESSON_REQUESTS',
}

// IMPORTANT: since the permissions are stored as a json column, the enum values should not be changed
export enum Scope {
  NONE = 0,
  READ = 1,
  READ_WRITE = 2,
  READ_WRITE_DELETE = 3,
}

export const possiblePermissionScopes: Record<Permission, Scope[]> = {
  [Permission.OWN_CALENDAR]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.OWN_VACATION]: [Scope.READ, Scope.READ_WRITE_DELETE],
  [Permission.ALL_CALENDARS]: [Scope.NONE, Scope.READ, Scope.READ_WRITE_DELETE],
  [Permission.CALENDAR_SELECT_ALL_VEHICLES]: [Scope.NONE, Scope.READ_WRITE],
  [Permission.CALENDAR_AUDIT_LOG]: [Scope.NONE, Scope.READ],
  [Permission.ALL_VEHICLE_CALENDARS]: [Scope.NONE, Scope.READ],
  [Permission.OWN_VEHICLE_CALENDAR]: [Scope.NONE, Scope.READ],
  [Permission.EDIT_PAST_EVENTS]: [
    Scope.NONE,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.OWN_TIME_TRACKING]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.ALL_TIME_TRACKING]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.VEHICLES]: [Scope.NONE, Scope.READ, Scope.READ_WRITE_DELETE],
  [Permission.VEHICLE_DETAIL]: [Scope.NONE, Scope.READ],
  [Permission.OWN_STUDENTS_INFO]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.ALL_STUDENTS_INFO]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.STUDENT_ASSIGN_INSTRUCTOR]: [Scope.NONE, Scope.READ_WRITE],
  [Permission.STUDENT_EXPORT]: [Scope.NONE, Scope.READ],
  [Permission.STUDENT_ATTACHMENTS]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.STUDENT_PAYMENTS]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.STUDENT_INVOICES]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.STUDENT_BALANCE]: [Scope.NONE, Scope.READ],
  [Permission.STUDENT_PAYMENT_REQUESTS]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
  ],
  [Permission.INITIATE_PAYMENT]: [Scope.NONE, Scope.READ_WRITE],
  [Permission.STUDENT_PROGRESS_CARDS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.STUDENT_EXAMS]: [Scope.NONE, Scope.READ, Scope.READ_WRITE_DELETE],
  [Permission.ALL_PAYMENTS]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.ALL_INVOICES]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.INVOICE_MAILING]: [Scope.NONE, Scope.READ_WRITE],
  [Permission.BILLING_TYPES]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.PRODUCTS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.INVOICE_SETTINGS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.PAYMENT_SETTINGS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.USERS]: [
    Scope.NONE,
    Scope.READ,
    Scope.READ_WRITE,
    Scope.READ_WRITE_DELETE,
  ],
  [Permission.SYLLABI]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.STUDENT_GROUPS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.ORGANIZATION_SETTINGS]: [Scope.NONE, Scope.READ_WRITE],
  [Permission.HOLIDAYS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.DROPOUT_REASONS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.LOCK_PERIODS]: [Scope.NONE, Scope.READ_WRITE],
  [Permission.SMS_SETTINGS]: [Scope.NONE, Scope.READ_WRITE],
  [Permission.MEETING_POINTS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.OFFICE_LOCATIONS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.EXAMINERS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.OWN_SUCCESS_REPORT]: [Scope.NONE, Scope.READ],
  [Permission.ALL_SUCCESS_REPORTS]: [Scope.NONE, Scope.READ],
  [Permission.BRANDING]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.LESSON_REQUEST_CONFIG]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.OWN_TODOS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.SENT_TODOS]: [Scope.READ_WRITE, Scope.READ_WRITE_DELETE],
  [Permission.SEND_TODOS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.OWN_LESSON_REQUESTS]: [Scope.READ_WRITE, Scope.READ_WRITE_DELETE],
  [Permission.ALL_LESSON_REQUESTS]: [Scope.NONE, Scope.READ_WRITE_DELETE],
  [Permission.USER_ROLES]: [Scope.NONE, Scope.READ, Scope.READ_WRITE_DELETE],
};

export type FullPermissionScope = Record<Permission, Scope>;
export type PermissionScope = Partial<FullPermissionScope>;
