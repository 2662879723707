import React, { FunctionComponent, SVGProps } from 'react';
import { Menu } from '@headlessui/react';
import { Color } from '../../../typings/backend-types';
import { Dropdown } from './Dropdown';
import { classNames } from 'utilities/classNames';

export interface DropDownMenuOption {
  label: string | JSX.Element | null;
  onClick: () => void;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  color?: Color;
}

interface Props {
  options: DropDownMenuOption[];
  align?: 'right' | 'left';
  verticalAlign?: 'top' | 'bottom';
}

export const DropdownMenu: FunctionComponent<Props> = ({
  options,
  children,
  align,
  verticalAlign,
}) => {
  return (
    <Dropdown button={children} align={align} verticalAlign={verticalAlign}>
      {options.map((o, i) => {
        const Icon = o.icon;
        return (
          <Menu.Item key={i}>
            {({ active }) => (
              <a
                className={classNames(
                  active ? 'bg-accent-100' : '',
                  'flex items-center space-x-2 px-4 py-2 text-sm hover:bg-accent-600 hover:text-white cursor-pointer',
                  `text-${o.color ?? Color.Slate}-600`,
                )}
                onClick={o.onClick}
              >
                {Icon && <Icon className="w-5 h-5 inline mr-1" />}
                <p className="flex-1 w-max">{o.label}</p>
              </a>
            )}
          </Menu.Item>
        );
      })}
    </Dropdown>
  );
};
