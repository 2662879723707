import { FunctionComponent } from 'react';

const PageHeader: FunctionComponent = ({ children }) => {
  return (
    <div className=" lg:max-w-6xl lg:mx-auto select-none">
      <div className=" px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
