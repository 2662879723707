import useSWR from 'swr';
import { UserType, useAppContext } from '../context/appContext';
import { AppUsageContract } from '@tr-types/backend-types';
import { HttpEndpoints } from 'data/httpEndpoints';
import authenticatedFetcher from 'data/authenticatedFetcher';
import { useCallback } from 'react';

export enum LimitedResources {
  ACTIVE_USERS,
  VEHICLES,
}

export enum RestrictedFeatures {
  SMS,
  MAILING,
  STUDENT_LOGIN,
  TIME_TRACKING,
  FINANCE_OVERVIEW,
  PAYMENT_PROCESSING,
  PAYMENT_IMPORT,
  SYLLABI,
  INVOICE_DELETION,
}

export default function useContract() {
  const { organizationId } = useAppContext(UserType.BOTH);

  const { data: contract, isValidating } = useSWR<AppUsageContract | null>(
    () =>
      HttpEndpoints.AppUsageContractEndpoints.getActiveForOrganization(
        organizationId,
      ),
    authenticatedFetcher,
  );

  const { data: activeUserCount } = useSWR<number>(
    () => HttpEndpoints.UserEndpoints.countActiveUsersForOrg(organizationId),
    authenticatedFetcher,
  );

  const { data: vehicleCount } = useSWR<number>(
    () => HttpEndpoints.VehicleEndpoints.countVehiclesForOrg(organizationId),
    authenticatedFetcher,
  );

  const isLimitReached = useCallback(
    (resource: LimitedResources) => {
      if (!contract) return true;
      if (resource === LimitedResources.ACTIVE_USERS) {
        if (contract.UL_maxUsers === null) return false; // no limit on users
        if (activeUserCount == null) return true; // no data on active users
        return activeUserCount >= contract.UL_maxUsers;
      }
      if (resource === LimitedResources.VEHICLES) {
        if (contract.UL_maxVehicles === null) return false; // no limit on vehicles
        if (vehicleCount == null) return true; // no data on vehicles
        return vehicleCount >= contract.UL_maxVehicles;
      }
      return false;
    },
    [activeUserCount, contract, vehicleCount],
  );

  const isFeatureEnabled = useCallback(
    (feature: RestrictedFeatures) => {
      if (!contract) return false;
      switch (feature) {
        case RestrictedFeatures.SMS:
          return contract.FF_sms;
        case RestrictedFeatures.MAILING:
          return contract.FF_mailing;
        case RestrictedFeatures.STUDENT_LOGIN:
          return contract.FF_student_login;
        case RestrictedFeatures.TIME_TRACKING:
          return contract.FF_time_tracking;
        case RestrictedFeatures.FINANCE_OVERVIEW:
          return contract.FF_finance_overview;
        case RestrictedFeatures.PAYMENT_PROCESSING:
          return contract.FF_payment_processing;
        case RestrictedFeatures.PAYMENT_IMPORT:
          return contract.FF_payment_import;
        case RestrictedFeatures.SYLLABI:
          return contract.FF_syllabi;
        case RestrictedFeatures.INVOICE_DELETION:
          return contract.FF_invoice_deletion;
        default:
          return true;
      }
    },
    [contract],
  );

  return {
    contract: contract || null,
    isLimitReached,
    isFeatureEnabled,
    // for some reason isValidating is undefined on first page load
    contractIsLoading: (isValidating && !contract) ?? true,
  };
}
