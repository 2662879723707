import {
  FEATURE,
  FEATURE_DEFAULT,
  FEATURE_EXCEPTIONS,
  FEATURE_STATE,
} from 'FEATURES';
import { UserType, useAppContext } from '../context/appContext';

export default function useFeatureToggles() {
  const { userId } = useAppContext(UserType.BOTH);

  const isFeatureEnabled = (feature: FEATURE): boolean => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: string) => searchParams.get(prop),
    });

    if (params[feature] != null) {
      return params[feature] !== 'false';
    }
    if (FEATURE_EXCEPTIONS[feature].includes(userId)) return true;
    return FEATURE_DEFAULT[feature] === FEATURE_STATE.ENABLED;
  };
  return { isFeatureEnabled };
}
