import Head from 'next/head';
import { FunctionComponent } from 'react';
import { UserType, useAppContext } from '../../context/appContext';

interface Props {
  title: string;
}

export const PageTitle: FunctionComponent<Props> = ({ title }) => {
  const { organization } = useAppContext(UserType.BOTH);

  return (
    <Head>
      <title>
        {title} · {organization.name}
      </title>
    </Head>
  );
};
