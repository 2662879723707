import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';
import Image from 'next/image';
import { usePersistedState } from '../../hooks/usePersistedState';
import { NavigationItemList } from './NavigationItemList';
import { NavigationItem } from './SidebarLayout';
import LanguageSelector from './LanguageSelector';
import { classNames } from 'utilities/classNames';

interface Props {
  mainNavigation: NavigationItem[];
  secondaryNavigation: NavigationItem[];
  organizationName: string;
  breakpoint?: string;
  organizationLogoUrl?: string;
}

export const NavigationBar: FunctionComponent<Props> = ({
  mainNavigation,
  secondaryNavigation: secondaryNavigation,
  organizationName,
  breakpoint,
  organizationLogoUrl,
}) => {
  const [isCollapsed, setIsCollapsed] = usePersistedState<boolean>(
    'sidebarLS',
    false,
  ); // Keep sidebar collapsed status on localstorage

  function toggleMainSidebarCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <div className={classNames(breakpoint)}>
      {/* setting overflow-x to "visible" when scrolling is possible on the y-axis is not possible. 
      To still show the tooltips on the collapsed sidebar, we need to add extra space to the right that belongs to the container, 
      but doesn't block other components, hence the -m-28. 
      Then the y-scrollbar shows up at the right of the invisble extra space, so we need to hide that.
       */}
      <div
        className={classNames(
          isCollapsed ? 'w-52 -mr-28' : 'w-64',
          ' overflow-y-auto ipad-overflow-scrolling relative hide-scrollbar',
        )}
      >
        <div
          className={classNames(
            isCollapsed ? 'w-24' : 'w-64',
            'bg-white py-4 min-h-screen h-max flex flex-col transition-all',
          )}
        >
          <div
            className={classNames(
              isCollapsed ? 'hidden' : 'visible',
              'text-center px-4 text-gray-500 font-medium select-none',
            )}
          >
            {organizationLogoUrl && (
              <Image
                alt="Logo"
                src={organizationLogoUrl}
                width={4 * 48}
                height={4 * 24}
                objectFit="contain"
                objectPosition="center"
              />
            )}

            <h1 className="truncate">{organizationName}</h1>
          </div>
          <nav
            className="mt-5 flex-1 flex flex-col divide-y divide-gray-200"
            aria-label="Sidebar"
          >
            <NavigationItemList
              navigation={mainNavigation}
              isCollapsed={isCollapsed}
            />
            <NavigationItemList
              className="mt-6 pt-6"
              navigation={secondaryNavigation}
              isCollapsed={isCollapsed}
            />
            {!isCollapsed && (
              <div className="mt-6 pt-6 pl-6">
                <LanguageSelector />
              </div>
            )}
            <button
              className="flex w-12 h-12 my-3 text-sm bg-white justify-center font-medium rounded-full border border-gray-200 self-center focus:outline-none outline-0 focus:ring-0 hover:text-gray-800 hover:bg-gray-200 ease-in duration-100"
              onClick={toggleMainSidebarCollapse}
            >
              {isCollapsed ? (
                <ChevronDoubleRightIcon className="w-6 h-6 text-gray-500 self-center" />
              ) : (
                <ChevronDoubleLeftIcon className="w-6 h-6 text-gray-500 self-center" />
              )}
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
