// 1. Add feature toggles here, with the `enable__` prefix.
export enum FEATURE {
  EXPERIMENTS_PAGE = 'enable__experiments_page',
  INVOICE_TYPES = 'enable__invoice_types',
  I18N = 'enable__i18n',
  STUDENT_PAYMENT_INITIATION = 'enable__student_payment_initiation',
}

export enum FEATURE_STATE {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

// 2. Add your new feature toggle's here with FEATURE_STATE.DISABLED.
// Once your feature is ready to be rolled out, change the state to FEATURE_STATE.ENABLED.
export const FEATURE_DEFAULT = {
  [FEATURE.EXPERIMENTS_PAGE]: FEATURE_STATE.ENABLED,
  [FEATURE.INVOICE_TYPES]: FEATURE_STATE.ENABLED,
  [FEATURE.I18N]: FEATURE_STATE.ENABLED,
  [FEATURE.STUDENT_PAYMENT_INITIATION]: FEATURE_STATE.DISABLED,
};

/**
 * holds user ids for which a feature should be enabled, even if it is disabled by default
 */
export const FEATURE_EXCEPTIONS: Record<FEATURE, string[]> = {
  [FEATURE.EXPERIMENTS_PAGE]: [],
  [FEATURE.INVOICE_TYPES]: [],
  [FEATURE.I18N]: [],
  [FEATURE.STUDENT_PAYMENT_INITIATION]: [],
};
