import { XMarkIcon } from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';
import { classNames } from '../../utilities/classNames';

interface Props {
  message?: string;
  isShown: boolean;
  onLearnMorePressed: () => void;
  onDismissPressed: () => void;
}

export const MOTDBanner: FunctionComponent<Props> = ({
  message,
  isShown,
  onLearnMorePressed,
  onDismissPressed,
}) => {
  const display = isShown ? 'absolute' : 'hidden';

  return (
    <div className={classNames(display, 'w-full z-50 bg-accent-600')}>
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-white">
            <span>{message}</span>

            <span
              className="block sm:ml-2 sm:inline-block"
              onClick={onLearnMorePressed}
            >
              <a href="#" className="text-white font-bold underline">
                Learn more <span aria-hidden="true">&rarr;</span>
              </a>
            </span>
          </p>
        </div>
        <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pr-2">
          <button
            type="button"
            className="flex p-2 rounded-md hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-white"
            onClick={onDismissPressed}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};
