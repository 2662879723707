import { Bars3Icon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import { FunctionComponent, SVGProps, useState } from 'react';
import { MOTDBanner } from '../Common/MOTDBanner';
import { usePersistedState } from '../../hooks/usePersistedState';
import { PageTitle } from '../Common/PageTitle';
import { useSidebarNavigation } from '../../hooks/SidebarLayout/useSidebarNavigation';
import SignInButton from './SignInButton';
import AccountDropDown from './AccountDropDown';
import { MobileNavigationBar } from './MobileNavigationBar';
import { NavigationBar } from './NavigationBar';
import { Popup } from '@components/InfoScreens/Popup';
import { UserType, useAppContext } from 'context/appContext';
import { LoadingIndicator } from '@components/Common/LoadingIndicator';

export interface NavigationItem {
  current?: boolean;
  name: string;
  href: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  hidden?: boolean;
  badge?: JSX.Element | string | number;
}

export interface FloatingActionButton {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  onClick: () => void;
}

export interface Notification {
  title: string;
  message: string | JSX.Element;
  icon?: JSX.Element;
}

interface Props {
  pageTitle?: string | null;
  header?: string | JSX.Element | null;
  fab?: FloatingActionButton;
}

const SidebarLayout: FunctionComponent<Props> = ({
  children,
  pageTitle,
  header,
  fab,
}) => {
  const motd = process.env.NEXT_PUBLIC_MOTD;

  const [motdDetailsOpen, setMotdDetailsOpen] = useState(false);
  const [showMotdBanner, setShowMotdBanner] = usePersistedState(motd, !!motd);

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] =
    useState<boolean>(false);

  const { isLoading, isAuthenticated, organizationLogoUrl, organization } =
    useAppContext(UserType.BOTH);

  const { mainNavigation: navigation, secondaryNavigation } =
    useSidebarNavigation();

  if (isLoading) {
    return (
      <div className="grid place-items-center h-screen">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="absolute inset-0 flex overflow-hidden bg-accent-bg">
      {pageTitle && <PageTitle title={pageTitle} />}

      <MOTDBanner
        message={motd}
        isShown={showMotdBanner ?? false}
        onLearnMorePressed={() => setMotdDetailsOpen(true)}
        onDismissPressed={() => setShowMotdBanner(false)}
      />

      <MobileNavigationBar
        isOpen={isMobileSidebarOpen}
        onSidebarOpen={setIsMobileSidebarOpen}
        organizationName={organization?.name}
        mainNavigation={navigation}
        secondaryNavigation={secondaryNavigation}
        breakpoint="lg:hidden"
        organizationLogoUrl={organizationLogoUrl}
      />
      <NavigationBar
        organizationName={organization?.name}
        mainNavigation={navigation}
        secondaryNavigation={secondaryNavigation}
        breakpoint="hidden lg:flex"
        organizationLogoUrl={organizationLogoUrl}
      />

      <div className="flex-1 overflow-auto focus:outline-none">
        {/* App Bar */}
        <div className="flex-shrink-0 flex h-16 bg-white lg:bg-transparent sticky lg:relative top-0 z-30 shadow-sm lg:shadow-none">
          <div className="flex-1 px-4 flex justify-between sm:px-6 w-screen lg:mx-auto lg:px-8 gap-3 items-center">
            <button
              type="button"
              className="bg-white text-gray-400 hover:text-gray-500 flex items-center justify-center h-11 w-11 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-accent-500 lg:hidden"
              onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-8 w-8" aria-hidden="true" />
            </button>
            <h1 className="flex my-auto text-lg font-medium text-gray-800 lg:hidden truncate">
              <div className="truncate">{header || pageTitle}</div>
            </h1>
            <div className="flex-1 hidden lg:flex" />
            <div className="mt-2 ml-4 flex items-center md:ml-6 flex-shrink-0">
              {/* Profile dropdown */}
              {!isAuthenticated && <SignInButton />}
              {isAuthenticated && <AccountDropDown />}
            </div>
          </div>
        </div>

        <main className="flex-1 relative z-0">{children}</main>
      </div>

      {/* Floating action button */}

      {fab && (
        <div className="fixed bottom-8 right-8 z-50 lg:hidden">
          <div className="rounded-full shadow bg-white">
            <div
              onClick={fab.onClick}
              className="flex items-center justify-center h-16 w-16 rounded-full"
            >
              <fab.icon className="text-accent-500 w-8 h-8" />
            </div>
          </div>
        </div>
      )}

      {/* MOTD Popup */}
      <Popup
        open={motdDetailsOpen}
        onClose={() => setMotdDetailsOpen(false)}
        message={process.env.NEXT_PUBLIC_MOTD_DETAILED}
        title={motd}
        icon={
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-accent-100">
            <SpeakerWaveIcon className="h-6 w-6 text-accent-600" />
          </div>
        }
        closeText="Dismiss"
      />
    </div>
  );
};

export default SidebarLayout;
