import React, { FunctionComponent } from 'react';
import { Resource } from '../../typings/backend-types';
import useContract from '../../hooks/useContract';
import { Permission, Scope } from '../../typings/roleConfig';
import { LoadingScreen } from '../Common/LoadingScreen';
import { useUserTeam } from '../../hooks/useUserTeam';
import { ErrorScreen } from '@components/InfoScreens/ErrorScreen';
import { useAppContext, UserType } from 'context/appContext';
import { assert, ErrorType } from 'utilities/errors';
import DefaultContentInset from '@components/PageContentWrapper/PageContentWrapper';
import SidebarLayout, {
  FloatingActionButton,
} from '@components/SidebarLayout/SidebarLayout';
import PageHeader from '@components/PageHeader';

interface Props {
  // authorizationRequirements interpreted as OR
  authorizationRequirements: AuthorizationRequirements | null;
  disableSidebar?: boolean;
  header?: JSX.Element | string | null;
  headerComponent?: JSX.Element;
  pageTitle?: string | null;
  fab?: FloatingActionButton;
}

export enum AuthorizationRequirement {
  STUDENT = 'Student',
  ADMIN = 'Admin',
  EMPLOYEE = 'Employee',
}

type AuthorizationRequirements =
  | AuthorizationRequirement
  | Partial<Record<Permission, Scope>>
  | Partial<Record<'accessedResource', Resource>>;

export const AuthorizedPageWrapper: FunctionComponent<Props> = ({
  authorizationRequirements,
  disableSidebar,
  pageTitle,
  header,
  headerComponent,
  children,
  fab,
}) => {
  const { isLoading, user, userType } = useAppContext(UserType.BOTH);
  const { contract, contractIsLoading } = useContract();
  const userTeam = useUserTeam();

  if (isLoading || !user || (contractIsLoading && !contract)) {
    return <LoadingScreen />;
  }

  if (
    !user ||
    !user.id ||
    !user.organization ||
    (userType === UserType.EMPLOYEE && user.archived) ||
    (userType === UserType.STUDENT && !contract?.FF_student_login)
  ) {
    return <ErrorScreen type={ErrorType.UNAUTHORIZED} logoutOnReturn />;
  }

  if (authorizationRequirements) {
    switch (authorizationRequirements) {
      case AuthorizationRequirement.STUDENT:
        if (userType !== UserType.STUDENT) {
          return <ErrorScreen type={ErrorType.UNAUTHORIZED_STUDENT_PAGE} />;
        }
        break;
      case AuthorizationRequirement.ADMIN:
        if (!user.role?.isAdmin) {
          return <ErrorScreen type={ErrorType.UNAUTHORIZED_ADMIN_PAGE} />;
        }
        break;
      case AuthorizationRequirement.EMPLOYEE:
        if (userType !== UserType.EMPLOYEE) {
          return <ErrorScreen type={ErrorType.UNAUTHORIZED_ACCESS_RIGHT} />;
        }
        break;
      default:
        const authenticatedByPermissions = Object.entries(
          authorizationRequirements,
        ).some(
          ([permission, scope]) =>
            Math.min(
              user.role?.permissions[permission] ?? -1,
              contract ? Scope.READ_WRITE_DELETE : Scope.READ, // if there is no active contract, READ is the maximal scope
            ) >= scope,
        );
        // if there is no active contract, admins can only access pages for which there is some authorization requirement that has scope read
        const authenticatedByAdminRole =
          user.role.isAdmin &&
          (contract ||
            Object.entries(authorizationRequirements).some(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ([_permission, scope]) => scope <= Scope.READ,
            ));
        const authenticatedByAccessRights =
          'accessedResource' in authorizationRequirements &&
          (user.access_rights?.some(
            (r) => r.resource === authorizationRequirements.accessedResource,
          ) ||
            // all resources are also available to superiors of any user
            userTeam.length);
        if (
          !authenticatedByAdminRole &&
          !authenticatedByPermissions &&
          !authenticatedByAccessRights
        ) {
          return <ErrorScreen type={ErrorType.UNAUTHORIZED_ACCESS_RIGHT} />;
        }
    }
  }

  if (disableSidebar) {
    return <>{children}</>;
  }

  assert(header || headerComponent, 'Page with sidebar must have a header.');
  return (
    <SidebarLayout pageTitle={pageTitle} header={header} fab={fab}>
      {headerComponent ? (
        <PageHeader>{headerComponent}</PageHeader>
      ) : (
        <div className="hidden lg:block">
          <PageHeader>
            <h1 className="text-2xl font-bold text-gray-800 hidden lg:block">
              {header}
            </h1>
          </PageHeader>
        </div>
      )}

      <DefaultContentInset>{children}</DefaultContentInset>
    </SidebarLayout>
  );
};

AuthorizedPageWrapper.defaultProps = {
  authorizationRequirements: null,
  disableSidebar: false,
};
