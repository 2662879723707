import React, { FunctionComponent } from 'react';

const PageContentWrapper: FunctionComponent = ({ children }) => {
  return (
    <div className="relative max-w-6xl min-h-full mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8 pb-14">
      <div>{children}</div>
    </div>
  );
};

export default PageContentWrapper;
