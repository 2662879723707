import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, FunctionComponent } from 'react';
import Image from 'next/image';
import { NavigationItemList } from './NavigationItemList';
import { NavigationItem } from './SidebarLayout';
import LanguageSelector from './LanguageSelector';
import { classNames } from 'utilities/classNames';

interface Props {
  isOpen: boolean;
  onSidebarOpen: (value: boolean) => void;
  organizationName: string;
  mainNavigation: NavigationItem[];
  secondaryNavigation: NavigationItem[];
  breakpoint?: string;
  organizationLogoUrl?: string;
}

export const MobileNavigationBar: FunctionComponent<Props> = ({
  isOpen,
  onSidebarOpen,
  organizationName,
  mainNavigation,
  secondaryNavigation,
  breakpoint,
  organizationLogoUrl,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('fixed inset-0 flex z-40', breakpoint)}
        onClose={onSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-14 pt-2">
                <button
                  type="button"
                  className="bg-white flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                  onClick={() => onSidebarOpen(!isOpen)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="h-6 w-6 text-gray-500"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <div className="overflow-y-scroll h-screen">
              <div className="text-center px-4 text-gray-500 font-medium select-none">
                {organizationLogoUrl && (
                  <Image
                    alt="Logo"
                    src={organizationLogoUrl}
                    width={4 * 48}
                    height={4 * 24}
                    objectFit="contain"
                    objectPosition="center"
                  />
                )}
                <h1>{organizationName}</h1>
              </div>
              <nav
                className="mt-5 flex-shrink-0 divide-y divide-gray-200"
                aria-label="Sidebar"
              >
                <NavigationItemList navigation={mainNavigation} />
                {secondaryNavigation.filter((item) => !item.hidden).length >
                  0 && (
                  <div className="mt-6 pt-6">
                    <NavigationItemList navigation={secondaryNavigation} />
                  </div>
                )}
                <div className="mt-6 pt-6 pl-6">
                  <LanguageSelector />
                </div>
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};
