import Link from 'next/link';
import { FunctionComponent } from 'react';
import { NavigationItem } from './SidebarLayout';
import { classNames } from 'utilities/classNames';

interface Props {
  navigation: NavigationItem[];
  isCollapsed?: boolean;
  className?: string;
}

interface TooltipProps {
  selectedItem: boolean;
}

const NavigationItemTooltip: FunctionComponent<TooltipProps> = ({
  selectedItem,
  children,
}) => {
  return (
    <span
      className={classNames(
        selectedItem
          ? 'bg-accent-100 text-accent-600'
          : 'text-white bg-accent-600',
        'absolute w-max p-4 rounded-xl left-20 text-xs z-20 transition-all duration-100 origin-left scale-0 group-hover:scale-100',
      )}
    >
      {children}
    </span>
  );
};

export const NavigationItemList: FunctionComponent<Props> = ({
  navigation,
  isCollapsed,
  className,
}) => {
  if (navigation.every((n) => n.hidden)) return null;

  return (
    <div className={classNames(className, 'px-5 space-y-1')}>
      {navigation.map(
        (item) =>
          !item.hidden && (
            <Link key={item.name} href={item.href}>
              <a
                className={classNames(
                  item.current
                    ? 'bg-accent-100 text-accent-600'
                    : 'text-gray-500 hover:text-white hover:shadow-lg hover:shadow-accent-300/50',
                  'group flex items-center text-sm px-4 py-3 leading-6 font-medium rounded-xl from-40%',
                  isCollapsed && 'justify-center',
                  !item.current && 'hover:bg-accent-main', //'hover:bg-gradient-to-r hover:from-accent-main from-20% to-80% hover:to-magenta-main',
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                <div className="relative">
                  <item.icon
                    className={classNames(
                      item.current
                        ? 'text-accent-600'
                        : 'text-gray-500 group-hover:text-white',
                      'flex-shrink-0 h-6 w-6',
                    )}
                    aria-hidden="true"
                  />
                  {isCollapsed && item.badge && (
                    <span className="absolute -top-4 -right-4 bg-magenta-500 text-white text-xs font-semibold justify-center inline-flex items-center min-w-[20px] px-1 h-5 rounded-full">
                      {item.badge}
                    </span>
                  )}
                </div>
                {!isCollapsed ? (
                  <>
                    <p className="truncate ml-4 flex-grow">{item.name}</p>
                    {item.badge != null && (
                      <span className="bg-magenta-500 text-white text-xs font-semibold ml-2 justify-center inline-flex items-center px-1 min-w-[20px] h-5 rounded-full">
                        {item.badge}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    <NavigationItemTooltip selectedItem={item.current ?? false}>
                      {item.name}
                    </NavigationItemTooltip>
                  </>
                )}
              </a>
            </Link>
          ),
      )}
    </div>
  );
};
